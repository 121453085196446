* {
    margin: 0 auto;
}

.container-fluid.login {
    padding-right: 0;
    height: 100%;
}

.login {
    padding: 0px;
}

.login .row {
    padding: 0px;
    margin: 0px;
    /*Remove a margem excedente, tirando o scroll lateral*/
}

.login .col-5 {
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 5%;
    padding-right: 6%;
    padding-left: 6%;
}

.login .col-5 img {
    width: 90%;
    margin-bottom: 10%;
}

.login .align-items-center {
    margin-top: 15%;
}

.preenchimento {
    margin-top: 10%;
}

.campos {
    margin-top: 9.3%;
}

.campos-inferior {
    margin-top: 5%;
}



.login .col-7 {
    /*padding-left: 30px;*/
    padding-left: 0px;
    padding-right: 0px;
}

.login .col-7 img {}

.login .align-items-end {
    margin-top: 10%;

}

.col-5 .forgot {
    margin-top: 39%;
}

.col-5 .send {
    margin-top: 50.2%;
}


@media (max-width: 1500px) {
    .login .align-items-end {
        margin-top: 7%;
        font-size: 12px;

    }

    .col-5 .forgot {
        margin-top: 31%;
    }

    .col-5 .send {
        margin-top: 47%;
    }
}