@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');


* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}

.table-hover:hover {
    background-color: rgba(246, 249, 253, 1);
}