.container-fluid.lateral {
    padding: 0;
    margin: 0;
    background-color: white;
    height: 100vh;
    width: 26.2vh;
    box-shadow: gray 0px 0px 2px;
    z-index: 5;
}

.lateral a {
    text-decoration: none;
    color: red;
}

.lateral .col-6 {
    color: blue;
    margin-top: 10px;
}

.lateral .col-3 {
    color: blue;
    margin-top: 10px;

}

.col-3.icon {
    padding-left: 35px;

}

.lateral .row.button {
    width: 240px;
}

.lateral .row.justify-content-md-center img {
    margin-top: 20%;
    width: 200px;
    padding-bottom: 50%;
    margin-left: 6%;
}

@media (max-width: 1400px) {
    .container-fluid.lateral {
        background-color: white;
        height: 100%;
        width: 26.2vh;
    }
    .lateral .row.justify-content-md-center img {
        margin-top: 20%;
        width: 170px;
        margin-left: 10%;
    }

}