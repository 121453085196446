.navbar {
    position: relative;
    padding-top: 0;
    padding-right: 0px;
    width: 100%;
    z-index: 1;

}

.navbar .col-sm-1 {
    margin-top: 1%;


}

.col-sm-1 button.btn {
    margin-left: 70%;

}

.navbar .col-sm-3 {
    display: flex;
}

.navbar .col-sm-3 * {
    margin-left: 30px;
}

.navbar .col-sm-3 .row * {
    margin: 0px;

}

.navbar .col-sm-3 .row .col-sm-3 {
    padding-right: 40px;
    padding-top: 6%;
}

.navbar .col-sm-3 .row .col-sm-3:last-child {
    margin-top: -4%;
}

.navbar .col-sm-3 .row .col-sm-6 {
    margin-top: 4%;
    padding-top: 5%;
}


@media (max-width: 1500px) {
    .navbar {}

    .navbar .col-sm-1 {
        margin-top: 1.8%;

    }

    .navbar .col-sm-3 .row .col-sm-3 {
        padding-top: 8%;
    }

    .navbar .col-sm-3 .row .col-sm-6 {
        margin-top: 4%;
        padding-top: 8%;
    }

    .navbar .col-sm-3 .row .col-sm-3:last-child {
        margin-top: -9%;
    }

    .col-sm-6 .name {
        font-size: 13px;
        font-weight: bolder;
    }
}