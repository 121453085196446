/* Example Styles for React Tags*/
#tags {
    padding: 40px;

}

div.ReactTags__tags {
    position: relative;
    border-bottom: 1px solid #eee;
    width: 88%;
    /*margin-bottom: 0px;*/

}

/* Styles for the input */
div.ReactTags__tagInput {
    width: 200px;
    border-radius: 2px;
    display: inline-block;
    margin-bottom: 0px;

}

div.ReactTags__tagInput input.ReactTags__tagInputField {
    height: 31px;
    margin: 0;
    font-size: 12px;

    border: none;
    padding: 0 4px;

}

div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
    box-shadow: 0 0 0 0;
    outline: 0;

}

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
    font-size: 12px;
    display: inline-block;
    padding: 3px;
    margin: 0 5px;
    margin-bottom: 3px;
    cursor: move;
    border-radius: 2px;
    background: #005CA4;
    border-radius: 4px;
    color: rgba(222, 222, 222, 1);
    box-shadow: 0px 0px 4px rgba(0, 92, 164, 0.3);



}



div.ReactTags__selected button.ReactTags__remove {
    background-color: #005CA4;
    padding: 0;
    margin-left: 5px;
    border: none;
    color: rgba(255, 255, 255, 1);
    font-size: 15px;
    font-weight: bolder;
    padding: 0px;
    position: relative;


}


/* Styles for suggestions */
div.ReactTags__suggestions {
    position: absolute;
}

div.ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: .05em .01em .5em rgba(0, 0, 0, .2);
    background: white;
    width: 200px;
}

div.ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
    margin: 0;
}

div.ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
}

div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
}