@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
* {
    margin: 0 auto;
}

.container-fluid.login {
    padding-right: 0;
    height: 100%;
}

.login {
    padding: 0px;
}

.login .row {
    padding: 0px;
    margin: 0px;
    /*Remove a margem excedente, tirando o scroll lateral*/
}

.login .col-5 {
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 5%;
    padding-right: 6%;
    padding-left: 6%;
}

.login .col-5 img {
    width: 90%;
    margin-bottom: 10%;
}

.login .align-items-center {
    margin-top: 15%;
}

.preenchimento {
    margin-top: 10%;
}

.campos {
    margin-top: 9.3%;
}

.campos-inferior {
    margin-top: 5%;
}



.login .col-7 {
    /*padding-left: 30px;*/
    padding-left: 0px;
    padding-right: 0px;
}

.login .col-7 img {}

.login .align-items-end {
    margin-top: 10%;

}

.col-5 .forgot {
    margin-top: 39%;
}

.col-5 .send {
    margin-top: 50.2%;
}


@media (max-width: 1500px) {
    .login .align-items-end {
        margin-top: 7%;
        font-size: 12px;

    }

    .col-5 .forgot {
        margin-top: 31%;
    }

    .col-5 .send {
        margin-top: 47%;
    }
}
#error {
    background-color: black;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
    margin-top: -3%;
    position: absolute;

}
.container-fluid.lateral {
    padding: 0;
    margin: 0;
    background-color: white;
    height: 100vh;
    width: 26.2vh;
    box-shadow: gray 0px 0px 2px;
    z-index: 5;
}

.lateral a {
    text-decoration: none;
    color: red;
}

.lateral .col-6 {
    color: blue;
    margin-top: 10px;
}

.lateral .col-3 {
    color: blue;
    margin-top: 10px;

}

.col-3.icon {
    padding-left: 35px;

}

.lateral .row.button {
    width: 240px;
}

.lateral .row.justify-content-md-center img {
    margin-top: 20%;
    width: 200px;
    padding-bottom: 50%;
    margin-left: 6%;
}

@media (max-width: 1400px) {
    .container-fluid.lateral {
        background-color: white;
        height: 100%;
        width: 26.2vh;
    }
    .lateral .row.justify-content-md-center img {
        margin-top: 20%;
        width: 170px;
        margin-left: 10%;
    }

}
.navbar {
    position: relative;
    padding-top: 0;
    padding-right: 0px;
    width: 100%;
    z-index: 1;

}

.navbar .col-sm-1 {
    margin-top: 1%;


}

.col-sm-1 button.btn {
    margin-left: 70%;

}

.navbar .col-sm-3 {
    display: flex;
}

.navbar .col-sm-3 * {
    margin-left: 30px;
}

.navbar .col-sm-3 .row * {
    margin: 0px;

}

.navbar .col-sm-3 .row .col-sm-3 {
    padding-right: 40px;
    padding-top: 6%;
}

.navbar .col-sm-3 .row .col-sm-3:last-child {
    margin-top: -4%;
}

.navbar .col-sm-3 .row .col-sm-6 {
    margin-top: 4%;
    padding-top: 5%;
}


@media (max-width: 1500px) {
    .navbar {}

    .navbar .col-sm-1 {
        margin-top: 1.8%;

    }

    .navbar .col-sm-3 .row .col-sm-3 {
        padding-top: 8%;
    }

    .navbar .col-sm-3 .row .col-sm-6 {
        margin-top: 4%;
        padding-top: 8%;
    }

    .navbar .col-sm-3 .row .col-sm-3:last-child {
        margin-top: -9%;
    }

    .col-sm-6 .name {
        font-size: 13px;
        font-weight: bolder;
    }
}
.card {
    flex-wrap: nowrap;
    margin-right: 30px;
    background-color: #F5F6F9;
    border: none;


}

.card .row {
    height: 152px;
}

.elementMargin {
    margin-top: 60px;
    z-index: 0;
}
.progress {
    margin-top: 7px;
    height: 6px !important;
}
.login .col-2 {
    /*background-color: lightblue;*/
    padding: 0;
    background-color: #F5F6F9;
    z-index: 1;
}

.login .col-10 {
    padding: 0;
    background-color: #F5F6F9;
    z-index: 2;
}



/* .login .col-2 {

    padding: 0;
    background-color: #F5F6F9;
    z-index: 1;
}
 */
/* .login .col-10 {
    padding: 0;

    background-color: #F5F6F9;
    z-index: 2;
}

.login {
    padding: 0;
} */

.MuiPaper-rounded {
    /* z-index: 0 !important; */
}

/* MuiTableRow-root title MuiTableRow-head */
.MuiTableRow-root.title * {
    z-index: 0 !important;
}
.table-hover:hover {
    background-color: rgba(246, 249, 253, 1);
}
/* Example Styles for React Tags*/
#tags {
    padding: 40px;

}

div.ReactTags__tags {
    position: relative;
    border-bottom: 1px solid #eee;
    width: 88%;
    /*margin-bottom: 0px;*/

}

/* Styles for the input */
div.ReactTags__tagInput {
    width: 200px;
    border-radius: 2px;
    display: inline-block;
    margin-bottom: 0px;

}

div.ReactTags__tagInput input.ReactTags__tagInputField {
    height: 31px;
    margin: 0;
    font-size: 12px;

    border: none;
    padding: 0 4px;

}

div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
    box-shadow: 0 0 0 0;
    outline: 0;

}

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
    font-size: 12px;
    display: inline-block;
    padding: 3px;
    margin: 0 5px;
    margin-bottom: 3px;
    cursor: move;
    border-radius: 2px;
    background: #005CA4;
    border-radius: 4px;
    color: rgba(222, 222, 222, 1);
    box-shadow: 0px 0px 4px rgba(0, 92, 164, 0.3);



}



div.ReactTags__selected button.ReactTags__remove {
    background-color: #005CA4;
    padding: 0;
    margin-left: 5px;
    border: none;
    color: rgba(255, 255, 255, 1);
    font-size: 15px;
    font-weight: bolder;
    padding: 0px;
    position: relative;


}


/* Styles for suggestions */
div.ReactTags__suggestions {
    position: absolute;
}

div.ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: .05em .01em .5em rgba(0, 0, 0, .2);
    background: white;
    width: 200px;
}

div.ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
    margin: 0;
}

div.ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
}

div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
}
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}

.table-hover:hover {
    background-color: rgba(246, 249, 253, 1);
}
